import { render, staticRenderFns } from "./Petshop.vue?vue&type=template&id=add24afa&scoped=true&"
import script from "./Petshop.vue?vue&type=script&lang=js&"
export * from "./Petshop.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "add24afa",
  null
  
)

export default component.exports