<template>
    <b-card no-body>
        <b-card-body class="pb-0" v-if="loading">
            <b-avatar
                class="mb-1"
                :variant="`light-${color}`"
                size="45"
            >
                <feather-icon
                    size="21"
                    :icon="icon"
                />
            </b-avatar>
            <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                    <b-spinner />
                </h2>
                <span>{{ statisticTitle }}</span>
            </div>
        </b-card-body>
        <b-card-body class="pb-0" v-else>
            <b-avatar
                class="mb-1"
                :variant="`light-${color}`"
                size="45"
            >
                <feather-icon
                    size="21"
                    :icon="icon"
                />
            </b-avatar>
            <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                    {{ statistic }}
                </h2>
                <span>{{ statisticTitle }}</span>
            </div>
        </b-card-body>

        <vue-apex-charts
            type="area"
            height="100"
            width="100%"
            :options="chartOptionsComputed"
            :series="[{name: chartData[0].name, data:[0,0]}]"
            v-if="loading"
        />
        <vue-apex-charts
            type="area"
            height="100"
            width="100%"
            :options="chartOptionsComputed"
            :series="chartData"
            v-else
        />

    </b-card>
</template>

<script>
import {BCard, BCardBody, BAvatar, BSpinner} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import {$themeColors} from '@themeConfig'
import {areaChartOptions} from './chartOptions'

export default {
    components: {
        VueApexCharts,
        BCard,
        BCardBody,
        BAvatar,
        BSpinner
    },
    props: {
        icon: {
            type: String,
            required: true,
        },
        statistic: {
            type: [Number, String],
            required: true,
        },
        statisticTitle: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'primary',
        },
        chartData: {
            type: Array,
            default: () => [],
        },
        chartOptions: {
            type: Object,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        chartOptionsComputed() {
            if (this.chartOptions === null) {
                const options = JSON.parse(JSON.stringify(areaChartOptions))
                options.theme.monochrome.color = $themeColors[this.color]
                return options
            } else {
                const options = JSON.parse(JSON.stringify(areaChartOptions))
                const custom = JSON.parse(JSON.stringify(this.chartOptions))
                const customKeys = Object.keys(custom)
                customKeys.forEach((e) => {
                    options[e] = custom[e]
                })
                options.theme.monochrome.color = $themeColors[this.color]
                return options
            }
        },
    },
}
</script>
